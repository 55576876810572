import React from "react";
import styled from "styled-components";
import MenuItemsData from "components/header/MenuItemsData";
import ProfileDropdown from "components/header/ProfileDropdown";
import Logo from "components/header/Logo";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import {withNamespaces} from "react-i18next";
import NotificationDot from "components/common/NotificationDot";
import {mediaQuery} from "components/common/styled/mediaQuery";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import LanguageDropDownContainer from "components/common/LanguageDropdown";
import UserTypes from "../../consts/userTypes";
import routes from "../../consts/routes";

const Container = styled.div`
    height: 100vh;
    position: fixed;
    /* left: 0px; */
    top: -16px;
    z-index: 10;

    ${props => (props.dir === "rtl" ? "right: 0px;" : "left: 0px;")};

    @media (max-width: 1220px) and (min-width: 1033px) {
        display: none;
        ${props => (props.toggleSidebar ? "display:block; " : "display:none;")}
    }

    @media (min-width: 1221px) {
        display: block;
    }

    ${mediaQuery.phone`display: none;`};
`;

const SidebarWrapper = styled.ul`
    height: 100vh;
    background-color: var(--main-theme-color);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    width: 8rem;
    /* text-align: center; */
    /* box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1); */
    /* padding: 44px; */
`;

const Item = styled.li`
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
    text-align: center;
    /* width: 150%; */
    transition: background-color 1+000ms ease;

    &:hover {
        color: var(--main-theme-color);
        background-color: var(--white);
    }

    &:last-child {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }

    &:last-child:hover {
        /* padding: 12px 0px; */
        /* margin-left: -24px; */
        background-color: var(--main-theme-color);
        color: var(--white);
    }

    ${props => props.active && "background: white; color: var(--main-theme-color);"};
`;

function Sidebar(props) {
    const {t, history, i18n, currentUser, myCoursesNotification, dir, toggleSidebar} = props;

    return (
        <Container dir={dir} toggleSidebar={toggleSidebar}>
            <SidebarWrapper>
                <Item>
                    <Logo width="88" />
                </Item>

                {currentUser &&
                    MenuItemsData({currentUser}).map((d, i) => {
                        let isAvailable = d.availableForUsers.includes(currentUser.role);
                        if (d.link === routes.mainDashboard.path && currentUser.role === UserTypes.child) {
                            isAvailable = currentUser.smsStudentInfo.isAgreementSigned;
                        }

                        const isActive = history.location.pathname.indexOf(d.link) === 0;
                        if (!isAvailable) {
                            return null;
                        }
                        const isCourseLink = d.link === "/courses";
                        return (
                            <Item id={d.id} active={isActive} key={i} onClick={() => history.push(d.link)}>
                                {d.icon ? d.icon : null}
                                <span>{t(d.title)}</span>
                                <NotificationDot visible={isCourseLink && myCoursesNotification} reversePadding />
                            </Item>
                        );
                    })}

                <Item>
                    <LanguageDropDownContainer
                        i18n={i18n}
                        wrapperStyle={{backgroundColor: ""}}
                        listStyle={{hoverColor: "rgba(100,100,100,0.1)"}}
                        above
                    />
                </Item>
            </SidebarWrapper>
        </Container>
    );
}

export default compose(
    withRouter,
    withNamespaces("translation")
)(Sidebar);
